import React from "react";
import "./WhyGenify.css";
import { Link } from "gatsby-plugin-anchor-links";

import Image from "../../assets/image/ezloan_logo/SVG/Logo_Blue.svg";
import transparency from "../../assets/image/svg/transparency.svg";
import usersegmentation from "../../assets/image/svg/usersegmentation.svg";
import pfm from "../../assets/image/svg/pfm.svg";
import future from "../../assets/image/svg/future.svg";
import gogreen from "../../assets/image/svg/gogreen.svg";
import SourceAgnostic from "../../assets/image/svg/diff/database.svg";
import GlobaCoverage from "../../assets/image/svg/diff/multilingual.svg";
import EasyIntegration from "../../assets/image/svg/diff/easy_integration.svg";
import Turnkey from "../../assets/image/svg/diff/turnkey.svg";
import Cloud from "../../assets/image/cloud-logo.png";
import ISO from "../../assets/image/iso-logo.png";
import Continious from "../../assets/image/continuous-logo.png";

const WhyGenify = () => {
  return (
    <section className="diff2-section-1">
      <div className="diff2-section-2">
        <div className="header-section">
          <h2 className="section-header">Why Genify?</h2>
          <p className="diff-section-h2">
            Your partner to unlock value in transaction data
          </p>
          <a
            style={{ alignSelf: "start" }}
            className="btn btn-primary btn-lg diff-a"
            href="/contact-us"
          >
            Get Started <i className="fas fa-chevron-right ml-4"></i>
          </a>
        </div>
        <div className="diff-grid">
          <div className="item-grid">
            <img src={EasyIntegration} alt="multilingual" />
            <h4 className="diff-section-h4 mt-4">Ease of Integration</h4>
            <p className="diff-section-p">
              Via a vast, well-documented set of API endpoints
            </p>
          </div>
          <div className="item-grid">
            <img src={ISO} alt="multilingual"  className="uncolored"/>
            <h4 className="diff-section-h4 mt-4">ISO 27001</h4>
            <p className="diff-section-p">
              Our servers are ISO 27001 certified, and our data usage is
              compliant with GDPR
            </p>
          </div>
          <div className="item-grid">
            {/* <img src={Turnkey} alt="multilingual" /> */}
            <img src={Cloud} alt="multilingual" className="uncolored" />
            <h4 className="diff-section-h4 mt-4">On Premises or Cloud</h4>
            <p className="diff-section-p">
              Our API runs on your premises or in the cloud, thanks to a hybrid
              build since Day 1
            </p>
          </div>
          <div className="item-grid">
            <img src={Continious} alt="multilingual" className="uncolored" />
            <h4 className="diff-section-h4 mt-4">Continuous upgrades</h4>
            <p className="diff-section-p">
              Your subscription gives access to new features, sourced directly
              from our existing users
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyGenify;
